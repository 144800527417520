<template>
    <b-row class="justify-content-center">
        <b-col md="12" lg="12" xl="10">
        <CCard>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(submit)">
                    <CCardHeader class="d-flex justify-content-between">
                        <h4 class="m-0">{{ $t('pages.vdi.datastores.headers.edit') }}</h4>
                    </CCardHeader>
                    <CCardBody>
                        <b-overlay :show="loading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                            <validation-provider
                                :name="$t('pages.vdi.datastores.form.datastore')"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="6"
                                    label-cols-lg="5"
                                >
                                    <template #label>
                                        {{ $t('pages.vdi.datastores.form.datastore') }}
                                    </template>
                                    <b-form-select
                                        disabled
                                        v-model="form.vCenterDatastoreRef"
                                        :options="datastoreOptions"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="datastore-feedback"
                                    ></b-form-select>
                                    <b-form-invalid-feedback id="datastore-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.vdi.datastores.form.name').toString()"
                                :rules="{ required: true, min: 3, max: 32 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="6"
                                    label-cols-lg="5"
                                    :label="$t('pages.vdi.datastores.form.name')"
                                >
                                    <b-form-input
                                        type="text"
                                        name="name"
                                        :state="getValidationState(validationContext)"
                                        v-model="form.name"
                                        aria-describedby="name-feedback"
                                    />
                                    <b-form-invalid-feedback id="name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                :name="$t('pages.vdi.datastores.form.description').toString()"
                                :rules="{ required: true, min: 3, max: 512 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="6"
                                    label-cols-lg="5"
                                >
                                    <template #label>
                                        {{ $t('pages.vdi.datastores.form.description') }}
                                    </template>
                                    <b-form-textarea
                                        name="description-de"
                                        :state="getValidationState(validationContext)"
                                        v-model="form.description"
                                        aria-describedby="description-de-feedback"
                                        rows="2"
                                        max-rows="5"
                                    ></b-form-textarea>
                                    <b-form-invalid-feedback id="description-de-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-overlay>
                    </CCardBody>
                    <CCardFooter class="d-flex justify-content-end">
                        <b-button type="submit" variant="orange">{{ $t('buttons.save') }}</b-button>
                    </CCardFooter>
                </b-form>
            </validation-observer>
        </CCard>
        </b-col>
    </b-row>
</template>

<script>
import datastoresService from "../../../services/datastoresService";
import api from "../../../services/api";
import fileService from "../../../services/fileService";

export default {
    name: "DatastoresEdit",
    props: ["id"],
    data: () => ({
        datastores: [],
        datastoreOptions: [],
        form: {
            name: '',
            description: '',
            vCenterDatastoreRef: null,
            vCenterDatastoreName: '',
            vCenterDatastoreType: ''
        },
        loading: false
    }),
    mounted() {
        this.loadDatastores();
        this.load();
    },
    methods: {
        load(){
            datastoresService.get(this.id).then(response => {
                this.form = response.data;
            }).catch();
        },
        loadDatastores() {
            api().get("vCenter/datastores").then(response => {
                this.datastores = response.data;
                this.datastoreOptions = response.data.map(i => ({value: i.datastore, text: `[${i.type}] ${i.name} (${fileService.bytesToSize(i.free_space)}/${fileService.bytesToSize(i.capacity)})`}));
            }).catch();
        },
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        submit() {
            if (!this.loading) {
                this.loading = true;
                let data = Object.assign({}, this.form)
                datastoresService.edit(data).then(() => {
                    this.$toastr.s(this.$t('pages.vdi.datastores.messages.success.edited'));
                    this.$router.push({name: 'VdiDatastores'})
                    this.loading = false;
                }).catch(error => {
                    this.errorHandler(error)
                    this.loading = false;
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
